import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Flex } from '@rebass/grid'
import { langs } from '../../langs'
import Section from '../Section'
import { DesktopFlex, MobileFlex, D, M } from '../breakpoints'
import { Text, Title, CTA, bgColor, BlogImg } from './blogComponents'

const Entities = require('html-entities').XmlEntities
const NavbarMargin = 65
const diffusePercentage = 41.25
const Diffuse = styled.div`
  background: linear-gradient(
    90deg,
    ${bgColor} ${diffusePercentage}%,
    rgba(44, 11, 65, 0.71) 50.39%,
    rgba(51, 51, 51, 0) 62.15%
  );
`
const HighlightMobileBg = styled.div`
  background-color: ${bgColor};
  height: 80%;
`

const highlightedImgStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: `${diffusePercentage}%`,
  width: `${100 - diffusePercentage}%`,
}

const highlightedImgMobileStyle = {
  position: 'relative',
  right: -15,
  height: 123,
  width: 'calc(100% - 130px)',
  bottom: -11,
  transform: 'skewY(0deg)',
}

const diffuseStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
}

const triangleStyle = {
  position: 'absolute',
  top: '18%',
  left: -60,
  width: 897,
  height: 483,
}

const triangleMobileStyle = {
  position: 'absolute',
  top: 50,
  right: -180,
  width: 738,
  height: 397,
}

const getHighlightedBg = (featured_media, triangle) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <DesktopFlex>
        <BlogImg style={highlightedImgStyle} media={featured_media} />
        <Diffuse style={diffuseStyle} />
        {triangle && (
          <Img style={triangleStyle} fluid={triangle.childImageSharp.fluid} />
        )}
      </DesktopFlex>
      <HighlightMobileBg />
      <MobileFlex>
        {triangle && (
          <Img
            style={triangleMobileStyle}
            fluid={triangle.childImageSharp.fluid}
          />
        )}
      </MobileFlex>
    </div>
  )
}

const HighlightedPostCard = props => {
  const { featured_media, title, slug } = props.post
  const triangle = props.triangle
  const lang = props.lang
  return (
    <Section
      pt={[30, 0]}
      pb={[0, 50]}
      background={getHighlightedBg(featured_media, triangle)}
    >
      <DesktopFlex>
        <Flex
          flexDirection="column"
          mt={NavbarMargin}
          pt={82}
          pb={93}
          css={{ maxWidth: 540 }}
        >
          <Text
            style={{
              textTransform: 'uppercase',
              color: 'white',
              marginBottom: 5,
            }}
          >
            featured article
          </Text>
          <Title style={{ marginBottom: 19 }}>{Entities.decode(title)}</Title>
          <CTA href={`${langs[lang]}/blog/${slug}`}>
            <Flex>
              <Flex>Read More</Flex>
              <Flex alignItems="center">
                {props.icon && (
                  <Img
                    style={{ marginLeft: 5, maxWidth: 10, maxHeight: 10 }}
                    fixed={props.icon.childImageSharp.fixed}
                  />
                )}
              </Flex>
            </Flex>
          </CTA>
          {/*<Text style={{ fontSize: 12, color: 'white', lineHeight: '16px' }}>
            9 min read
          </Text>*/}
        </Flex>
      </DesktopFlex>
      <MobileFlex>
        <Flex
          flexDirection="column"
          mt={NavbarMargin}
          pt={26}
          pb={67}
          mx={15}
          css={{ maxWidth: '100%' }}
        >
          <Text
            style={{
              textTransform: 'uppercase',
              color: 'white',
              fontSize: 14,
              lineHeight: '21px',
              marginBottom: 5,
            }}
          >
            featured article
          </Text>
          <Title style={{ fontSize: 32, lineHeight: '47px', marginBottom: 19 }}>
            {Entities.decode(title)}
          </Title>
          <CTA href={`${langs[lang]}/blog/${slug}`}>
            <Flex>
              <Flex>Read Article</Flex>
              <Flex alignItems="center">
                {props.icon && (
                  <Img
                    style={{ marginLeft: 5, maxWidth: 10, maxHeight: 10 }}
                    fixed={props.icon.childImageSharp.fixed}
                  />
                )}
              </Flex>
            </Flex>
          </CTA>
          {/*<Text style={{ fontSize: 12, color: 'white', lineHeight: '16px' }}>
            9 min read
          </Text>*/}
          <DesktopFlex>
            <BlogImg
              style={{
                width: '100%',
                height: '100%',
                transform: 'skewY(0deg)',
              }}
              media={featured_media}
            />
          </DesktopFlex>
          <MobileFlex style={{ justifyContent: 'flex-end' }}>
            <BlogImg style={highlightedImgMobileStyle} media={featured_media} />
          </MobileFlex>
        </Flex>
      </MobileFlex>
    </Section>
  )
}

export default HighlightedPostCard
