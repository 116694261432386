import React from 'react'
import { PageLink } from './blogComponents'
import { Flex } from '@rebass/grid'
import Img from 'gatsby-image'
import { D, M, DesktopFlex, MobileFlex } from '../breakpoints'

function pagination(c, m) {
  var delta = 2,
    range = [],
    rangeWithDots = [],
    l

  range.push(1)
  for (let i = c - delta; i <= c + delta; i++) {
    if (i < m && i > 1) {
      range.push(i)
    }
  }
  range.push(m)

  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }
  return rangeWithDots
}

const Paginator = props => {
  let { pageContext } = props
  let { lArrow, rArrow } = props.icons
  let { humanPageNumber, numberOfPages, pageNumber } = pageContext
  let pages = pagination(humanPageNumber, numberOfPages)

  return (
    <Flex justifyContent="center" alignItems="center">
      {pageNumber != 0 && (
        <PageLink href={pageContext.previousPagePath}>
          <DesktopFlex>
            {lArrow && (
              <Img
                style={{ marginLeft: 5, height: 10, width: 10 }}
                fixed={lArrow.childImageSharp.fixed}
              />
            )}
          </DesktopFlex>
          <MobileFlex>
            {lArrow && (
              <Img
                style={{ marginLeft: 5 }}
                fixed={lArrow.childImageSharp.fixed}
              />
            )}
          </MobileFlex>
        </PageLink>
      )}
      {pages.map((e, i) => {
        let link = `${pageContext.subseqPagePath}/${e}`
        if (e === '...') {
          return <PageLink key={i}>{e}</PageLink>
        }
        if (e == 1) {
          link = pageContext.firstPagePath
        }
        if (e == humanPageNumber) {
          return (
            <PageLink href={link} style={{ color: 'black' }} key={i}>
              {e}
            </PageLink>
          )
        } else {
          return (
            <PageLink href={link} key={i}>
              {e}
            </PageLink>
          )
        }
      })}
      {humanPageNumber != numberOfPages && (
        <PageLink href={pageContext.nextPagePath}>
          <DesktopFlex>
            {rArrow && (
              <Img
                style={{ marginLeft: 5, height: 10, width: 10 }}
                fixed={rArrow.childImageSharp.fixed}
              />
            )}
          </DesktopFlex>
          <MobileFlex>
            {rArrow && (
              <Img
                style={{ marginLeft: 5 }}
                fixed={rArrow.childImageSharp.fixed}
              />
            )}
          </MobileFlex>
        </PageLink>
      )}
    </Flex>
  )
}

export default Paginator
