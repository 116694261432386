import React from 'react'

import { BreakpointProvider } from 'react-socks'
import { Flex } from '@rebass/grid'
import { graphql } from 'gatsby'

import BlogPostCard from '../components/blog/blogPostCard'
import Footer from '../components/footer'
import Head from '../components/head'
import HighlightedPostCard from '../components/blog/highlightedArticle'
import Navbar from '../components/Navbar'
import Paginator from '../components/blog/paginator'
import { DesktopFlex, MobileFlex } from '../components/breakpoints'

const marginDesktop = '11%'
const marginMobile = 15
const PAGE_TITLE = 'Hubtype - Blog'

const ContentContainer = props => {
  return (
    <>
      <DesktopFlex>
        <Flex flexDirection="column" pt={52} pb={120} mx={marginDesktop}>
          {props.children}
        </Flex>
      </DesktopFlex>
      <MobileFlex>
        <Flex flexDirection="column" mt={10} mb={60} mx={marginMobile}>
          {props.children}
        </Flex>
      </MobileFlex>
    </>
  )
}

const Blog = props => {
  // useLangRedirect({
  //   'en-US': '/blog/',
  //   en: '/blog/',
  //   'en-UK': '/uk/blog/',
  //   'es-ES': '/es/blog/',
  //   es: '/es/blog/',
  // })
  const { data, pageContext } = props
  const { triangle, rarrowIconRed, larrowIcon, rarrowIcon } = data
  const { edges: posts } = data.allWordpressPost
  const getTranslation = post =>
    post.polylang_translations.find(
      tr => tr.polylang_current_lang == pageContext.lang
    )
  let postsLang = posts.filter(({ node: post }) => getTranslation(post))
  let hasPosts = true
  let highlightedPost = null
  try {
    highlightedPost = getTranslation(postsLang[0].node)
  } catch (e) {
    hasPosts = false
  }

  postsLang = postsLang.slice(1)

  const pageAwareAlternates = props.pageContext.i18n
  if (pageContext.humanPageNumber > 1 ) {
    pageAwareAlternates.forEach(item => {
      item.uri = `/blog/page/${pageContext.humanPageNumber}`
    })
  }
  /*let categories = [
    'CUSTOMER EXPERIENCE',
    'INNOVATION',
    'CHATBOTS',
    'LOREM IPSUM AMET',
    'CONSECETUR ADISCIPING SED',
  ]*/

  return (
    <>
      <BreakpointProvider>
        <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
          <Head
            alternates={pageAwareAlternates}
            autopilot={props.pageContext.autopilot}
            lang={pageContext.lang}
            title={PAGE_TITLE}
            uri={pageContext.firstPagePath}
          />
          <Navbar py={[12, 12, 12]} {...props} />
          {hasPosts && (
            <HighlightedPostCard
              post={highlightedPost}
              triangle={triangle}
              lang={pageContext.lang}
              icon={rarrowIconRed}
            />
          )}
          <Flex
            flexDirection="column"
            css={{ overflow: 'hidden', background: 'white' }}
          >
            <ContentContainer>
              {/* <CategoriesContainer items={categories} /> */}
              <Flex mt={[0, 52]} mb={30} flexWrap="wrap">
                {postsLang.map(({ node: post }, i) => {
                  let p = getTranslation(post)
                  return (
                    p && (
                      <BlogPostCard
                        key={post.id}
                        post={p}
                        lang={pageContext.lang}
                        //items={categories}
                      />
                    )
                  )
                })}
              </Flex>
              <Paginator
                icons={{ lArrow: larrowIcon, rArrow: rarrowIcon }}
                pageContext={pageContext}
              />
            </ContentContainer>
            <Footer {...props} style={{ marginTop: 80 }} />
          </Flex>
        </Flex>
      </BreakpointProvider>
    </>
  )
}

export default Blog

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!, $lang: String!) {
    triangle: file(relativePath: { eq: "blog/triangle.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rarrowIconRed: file(relativePath: { eq: "icons/rarrowIconRed.png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    rarrowIcon: file(relativePath: { eq: "icons/rarrowIcon.png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    larrowIcon: file(relativePath: { eq: "icons/larrowIcon.png" }) {
      childImageSharp {
        fixed(width: 20, height: 20) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allWordpressPost(
      filter: { polylang_current_lang: { eq: $lang } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          polylang_translations {
            polylang_current_lang
            wordpress_id
            title
            slug
            categories {
              name
            }
            featured_media {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1050, quality: 85) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
          id
        }
      }
    }
  }
`
